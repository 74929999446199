.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px; /* Adjust size as needed */
  height: 70px; /* Adjust size as needed */
  background-color: white;
  border: 1px solid #ccc; /* Optional: border to better visualize the box */
  border-radius: 6px;
}

a {
  text-decoration: none;
  color: #000;
}

@media (max-width: 767.98px) {
  .position-title,
  .date {
    margin-top: 5px;
  }
}
