.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full viewport height */
}

.rounded-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rounded-picture img {
  width: auto;
  height: 150%;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .rounded-picture {
    margin-bottom: 15px;
  }
}
