body {
  background-color: #070a0d;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.App {
  padding-top: 5%;
  margin: 0 20% 0 20%;
  @media screen and (max-width: 768px) {
    margin: 0 5% 0 5%;
  }
}
h1 {
  font-size: 36px;
  font-weight: 600;
  color: #f6f6f6;
}
h2 {
  font-size: 22px;
  font-style: italic;
  color: #fff9f6;
  font-family: "Gambetta", serif;
}
h3 {
  font-size: 20px;
  font-weight: normal;
  color: #f6f6f6;
}
h4 {
  font-size: 24px;
  font-style: italic;
  color: rgba(246, 246, 246, 0.65);
  opacity: 0.65;
  font-family: "Gambetta", serif;
}
p {
  font-size: 16px;
  font-weight: 300;
  color: rgba(246, 246, 246, 0.65);
}

html {
  scroll-behavior: smooth;
}
