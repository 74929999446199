#photos {
  column-count: 3;
  column-gap: 10px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.grid-item {
  break-inside: avoid;
  margin-bottom: 10px;
  position: relative;
}

.card {
  background-color: #f2f2f2;
  border: none;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}

.card img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.card__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.card:hover .card__content {
  opacity: 1;
  transform: translateY(0);
}

.card:hover img {
  transform: scale(1.1);
}

.card__title {
  margin: 0;
}

.card__description {
  margin: 10px 0 0;
  font-size: 75%;
  line-height: 1.4;
}

/* Responsive Styles */
@media (max-width: 768px) {
  #photos {
    column-count: 2;
  }
}

@media (max-width: 480px) {
  #photos {
    column-count: 1;
  }
}
