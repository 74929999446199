.main {
  background: radial-gradient(
    circle at bottom left,
    rgba(19, 19, 19, 1),
    rgba(9, 9, 9, 0.8) 35%,
    rgba(4, 4, 4, 0.6) 100%
  ) !important;
  color: white;
  box-shadow: 0 0 0 0.1px rgba(246, 246, 246, 0.65);
  border-radius: 3px;
  padding: 22px;
  padding-bottom: 14px;
  margin-bottom: 20px;
}
