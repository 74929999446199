/* CustomButton.css */
button.custom-button {
  padding: 0.1em 0.25em;
  width: 13em;
  height: 4.2em;
  background-color: #070a0d;
  border: 0.08em solid #fff;
  border-radius: 0.3em;
  font-size: 12px;
  font-style: italic;
  color: #fff9f6;
  font-family: "Gambetta", serif;
  cursor: pointer;
}

button.custom-button span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.4em;
  width: 8.25em;
  height: 2.5em;
  background-color: #070a0d;
  border-radius: 0.2em;
  font-size: 1.5em;
  font-style: italic;
  color: #fff9f6;
  font-family: "Gambetta", serif;
  border: 0.08em solid #fff;
  box-shadow: 0 0.4em 0.1em 0.019em #fff;
}

button.custom-button span:hover {
  transition: all 0.5s;
  transform: translate(0, 0.4em);
  box-shadow: 0 0 0 0 #fff;
}

button.custom-button span:not(:hover) {
  transition: all 1s;
}
